<template>
  <div class="my_tableslist_page home_page">
    <div class="content" v-if="tableData.length > 0">
      <ul ref="middle">
        <li v-for="(item, index) in tableData" :key="index">
          <div>
            <div class="title" @click="handleClick(item, 'view')">
              <span class="item_icon"><i class="el-icon-document-checked"></i></span>

              {{ item.name }} <span class="type">{{ item.type }}</span>
            </div>
            <div class="gray_label">
              <span v-if="userInfo && !userInfo.is_new">{{ item.type }}</span>
              <span>{{ item.score1 }}分</span>

              <span>{{ item.course }}</span>
            </div>
          </div>

          <div class="right_box">
            <span>靠谱率 {{ item.probability }}</span>
            <span class="btn_box">
              <div @click="handleClick(item, 'view')">查看</div>
              <div @click="handleClick(item, 'rename')">重命名</div>
              <div @click="handleClick(item, 'delete')">删除</div>
            </span>
            <i style="vertical-align: text-bottom" class="el-icon-arrow-right"></i>
          </div>
        </li>
      </ul>
    </div>
    <div v-else class="no_data">
      <img :src="noData" />
      <span class="text">暂无数据</span>
    </div>
    <van-popup v-model="dialogVisible" closeable close-icon-position="top-right" position="bottom"
      :style="{ height: '30vh', borderRadius: '10px 10px 0 0' }">
      <div class="popup_title">重命名</div>
      <el-form :model="form" ref="form" @submit.native.prevent>
        <el-form-item label="志愿表名称" prop="name">
          <el-input v-model="form.name" clearable @keyup.enter.native="keyup($event)"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noData from '@/assets/image/nodata.png';

export default {
  data() {
    return {
      tableData: [],
      planList: [],
      page: {
        page: 1,
        total: 0,
      },
      noData: noData,
      dialogVisible: false,
      form: {
        name: '',
        id: '',
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
      // isAuthed: state => (state.userinfo ? true : false),
    }),
  },
  mounted() {
    window.addEventListener('scroll', this.windowScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.windowScroll); //销毁滚动事件
  },
  created() {
    // this.getMyVolunteer();
    this.tableData = [];
    this.getPlanList();
  },
  methods: {
    //获取当前可视范围的高度
    getClientHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = Math.min(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      } else {
        clientHeight = Math.max(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      }
      return clientHeight;
    },

    //获取文档完整的高度
    getScrollHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      );
    },
    // 防抖函数
    debounce(fn, delay) {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          console.log('防抖');
          fn.apply(context, args);
        }, delay);
      };
    },
    //获取当前滚动条的位置
    getScrollTop() {
      var scrollTop = 0;
      //window.pageYOffset = document.documentElement.scrollTop
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
    //回调函数
    async windowScroll() {
      //获取三个值
      var scrollTop = this.getScrollTop();
      var clientHeight = this.getClientHeight();
      var scrollHeight = this.getScrollHeight();
      let top1 = this.$refs.middle.offsetTop;
      scrollTop > top1 + 133 ? (this.isFixed = true) : (this.isFixed = false);
      //如果满足公式则，确实到底了
      // console.log(scrollTop + clientHeight, scrollHeight)
      // 绝对值函数
      // console.log(parseInt(String(scrollTop + clientHeight)) - scrollHeight);
      //20230922修改加载方式
      // if (
      //   parseInt(String(scrollTop + clientHeight)) - scrollHeight === -1 ||
      //   parseInt(String(scrollTop + clientHeight)) - scrollHeight === 0
      // ) {
      //   //发送异步请求请求数据，同时携带offset并自增offset
      //   //noMore是自定义变量，如果是最后一批数据则以后都不加载
      //   console.log('滚动到底部');
      //   this.page.page += 1;
      //   this.debounce(this.getPlanList(), 3000);
      // }
      if (scrollTop + clientHeight >= scrollHeight - 8) {
        //发送异步请求请求数据，同时携带offset并自增offset
        //noMore是自定义变量，如果是最后一批数据则以后都不加载
        console.log('滚动到底部');
        if (!this.noMore) {
          this.page.page = this.page.page + 1;
          this.debounce(this.getPlanList(), 3000);
        }
      }
    },
    //我的志愿
    async getPlanList() {
      const res = await this.$axios.get(
        `/php/user.plan.list?page=${this.page.page}`
      );
      console.log(111, res);
      this.page.total = res.data.data.total;
      let list = [];
      list = res.data.data.list;
      this.tableData = [...this.tableData, ...list];
    },
    async getMyVolunteer() {
      const apiUrl = '/api/volunteer/';
      try {
        const response = await this.$axios.get(apiUrl);
        if (response.data.errno === 0) {
          this.tableData = response.data.results;
          this.tableData.forEach((item, index) => {
            this.$set(item, 'index', this.tableData.length - index);
          });
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //  async getPlanList() {
    //   const apiUrl = "/php/user.plan.list";
    //   try {
    //     const response = await this.$axios.get(apiUrl);
    //     if (response.data.errno === 0) {
    //       this.planList = response.data.results;
    //       this.tableData.forEach((item, index) => {
    //         this.$set(item, "index", this.tableData.length - index);
    //       });
    //     }
    //   } catch (error) {
    //     console.log(`question error: ${error}`);
    //   }
    // },
    // 重命名提交
    async submitForm() {
      if (this.form.name === '') {
        this.$message.error('志愿表名称不能为空');
        return;
      }
      const response = await this.$axios.post(
        '/php/user.plan.name.change',
        this.form
      );
      console.log('response', response);
      if (response.data.ret == 0) {
        this.$message.success('重命名成功');
        this.dialogVisible = false;
        this.tableData = [];
        this.page.page = 1;
        this.getPlanList();
      } else {
        this.$message({
          type: 'warning',
          message: '重命名失败，请稍后重试',
        });
        this.dialogVisible = false;
      }
    },
    //回车事件
    async keyup(event) {
      event.target.blur();
      await this.submitForm();
    },
    handleClick(row, name) {
      //跳转到志愿表详情
      if (name == 'view') {
        this.$router.push('/tzy/details?id=' + row.id);
      }
      //重命名
      if (name == 'rename') {
        this.dialogVisible = true;
        this.form.name = row.name;
        this.form.id = row.id;
      }
      //删除
      if (name == 'delete') {
        this.$confirm('此操作将永久删除该志愿表, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'table-list-message-box',
        })
          .then(async () => {
            // await this.$axios.post('/php/user.plan.delete', { id: row.id });
            // this.$message.success('删除成功');
            // this.tableData = [];
            // await this.getPlanList();
            const response = await this.$axios.post('/php/user.plan.delete', {
              id: row.id,
            });
            if (response.data.ret == 0) {
              this.$message.success('删除成功');
              this.tableData = [];
              this.page.page = 1;
              this.getPlanList();
            } else {
              this.$message({
                type: 'warning',
                message: '删除失败，请稍后重试',
              });
            }
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.my_tableslist_page {
  .content {
    padding: 0.3rem 0;
    margin-bottom: 1rem;

    .title {
      font-size: 0.28rem;
      font-weight: 600;
      margin-bottom: 0.2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item_icon {
        display: flex;
        width: 0.44rem;
        height: 0.44rem;
        border-radius: 50%;
        background: #e23636;
        margin-right: 0.12rem;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: #fff;
        }
      }
    }

    .date {
      color: #999;
      font-size: 0.2rem;
    }

    ul {
      li {
        margin-bottom: 0.2rem;
        background: #fff;
        padding: 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .type {
          margin-left: 8px;
          font-size: 12px;
          line-height: 18px;
          padding: 0 4px;
          border-radius: 4px;
          border: 1px solid #ccc;
          color: #999;
          font-weight: normal;
        }

        .gray_label {
          color: #777;
          margin-top: 10px;
          font-size: 0.2rem;
          margin-left: 0.6rem;

          span {
            margin-right: 0.13rem;
          }
        }

        .el-icon-arrow-right {
          font-size: 0.32rem;
          color: #999;
        }
      }
    }
  }

  .no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;

    img {
      width: 180px;
    }

    .text {
      font-size: 16px;
      color: #666;
      text-align: center;
      width: 100%;
      font-weight: 600;
      padding-top: 20px;
    }
  }
}

.right_box {
  display: flex;
  align-items: center;
}

.btn_box {
  display: inline-flex;
  width: 15vw;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  div {
    color: #910000;
    padding: 3px 0;
  }
}

.popup_title {
  height: 8vh;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  display: inline-flex;
  align-items: center;
}

/deep/ .el-form-item__label {
  display: flex;
  float: none;
  padding: 0 20px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

/deep/ .el-input {
  margin: 10px 20px;
  width: calc(100% - 40px);
}

.dialog-footer {
  text-align: center;
}
</style>
